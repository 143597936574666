@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "testimonials.scss";

body { 
	padding-top:60px; 
	background: #cfedff url('/img/clouds.png') no-repeat center 20% fixed;	
}

/* GLOBALS */


.table-noborder td { border:none !important; }

 .btn-default {
  background-color: hsl(0, 0%, 79%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#dddddd");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#ffffff), to(#dddddd));
  background-image: -moz-linear-gradient(top, #ffffff, #dddddd);
  background-image: -ms-linear-gradient(top, #ffffff, #dddddd);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #dddddd));
  background-image: -webkit-linear-gradient(top, #ffffff, #dddddd);
  background-image: -o-linear-gradient(top, #ffffff, #dddddd);
  background-image: linear-gradient(#ffffff, #dddddd);
  border-color: #dddddd #dddddd hsl(0, 0%, 68.5%);
  color: #333 !important;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.69);
  -webkit-font-smoothing: antialiased;
}

.btn-danger {
    border-color: #861202;
    color: white;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    background-color: #d90007;
    background: -webkit-linear-gradient(#d90007, #c91400);
    background: linear-gradient(#d90007, #c91400);
}

.btn-success {
  background-color: hsl(110, 56%, 16%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#398f28", endColorstr="#193f11");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#398f28), to(#193f11));
  background-image: -moz-linear-gradient(top, #398f28, #193f11);
  background-image: -ms-linear-gradient(top, #398f28, #193f11);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #398f28), color-stop(100%, #193f11));
  background-image: -webkit-linear-gradient(top, #398f28, #193f11);
  background-image: -o-linear-gradient(top, #398f28, #193f11);
  background-image: linear-gradient(#398f28, #193f11);
  border-color: #193f11 #193f11 hsl(110, 56%, 11%);
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.33);
  -webkit-font-smoothing: antialiased;
}

.btn-primary {
  background-color: hsl(205, 100%, 25%) !important;
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0079d1", endColorstr="#004a7f");
  background-image: -khtml-gradient(linear, left top, left bottom, from(#0079d1), to(#004a7f));
  background-image: -moz-linear-gradient(top, #0079d1, #004a7f);
  background-image: -ms-linear-gradient(top, #0079d1, #004a7f);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0079d1), color-stop(100%, #004a7f));
  background-image: -webkit-linear-gradient(top, #0079d1, #004a7f);
  background-image: -o-linear-gradient(top, #0079d1, #004a7f);
  background-image: linear-gradient(#0079d1, #004a7f);
  border-color: #004a7f #004a7f hsl(205, 100%, 21%);
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.26);
  -webkit-font-smoothing: antialiased;
}

.panel .panel-heading {
	-webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3);
}

.panel-default .panel-heading {
	border-color: #861202;
    color: white;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    box-shadow: 0 1px 1px rgba(0,0,0,0.3);
    background-color: #d90007;
    background: -webkit-linear-gradient(#d90007, #c91400);
    background: linear-gradient(#d90007, #c91400);
}

// HEADER

.navbar-default {
	background: #c41200 url('/img/header_stars.png') no-repeat center;
	a { color:#fff !important; }
}

/*pages*/
#yelp_iframe { width:100%; height:100%; position:fixed; top:0; right:0; left:0; bottom:0; }
#hover_panel { 
	position: absolute; top:50px; font-size:20px; line-height:35px;   width:100%; z-index:9; 
	background:#fff; color:#c41200; height:40px; overflow:visible; 
	border:1px solid #c41200;

	.btn { margin:5px 10px; margin-right:30px;  }
}


 